<template>
  <div>
    <HxHomeContent></HxHomeContent>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import HxHomeContent from "./hx-home-content.vue";
export default {
  components: {
    HxHomeContent,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      darkMode: "user/darkMode",
    }),
  },
  created() {},
};
</script>

<style></style>
